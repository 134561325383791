import {
  GLSelectSearchOptions,
  RegisteredDevice,
  useGLPagination,
  useI18n,
  useRegisterDeviceStore,
  useToast,
} from "@group-link-one/grouplink-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  ChannelData,
  GetDevicesPendingActivationResponse,
  RegisterDeviceBody,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useGoogleMapsService } from "../../../Services/googleMapsService/useGoogleMapsService";

export const useModalRegisterDevice = () => {
  const { state: registerDeviceState, actions: registerDeviceActions } =
    useRegisterDeviceStore();

  const { googleMapsIsLoaded, getSuggestions, getPlaceDetails } =
    useGoogleMapsService();

  const { registerDevice } = useDeviceListService();
  const { addToast } = useToast();

  const { t } = useI18n();

  const queryClient = useQueryClient();
  const { state: paginationState } = useGLPagination();

  async function onRegisterDevice(registerData: RegisteredDevice) {
    try {
      if (!registerDeviceState.deviceId) return;

      const channelData: ChannelData = {
        channel: 0,
        channel_name: registerData.deviceName || "",
        remote_id: registerData.remoteId || "",
        // current_reading: "0",
        // pulse_factor_reference: "0",
        // read_whitelabel: "UNSUPPORTED",
      };

      const body: RegisterDeviceBody = {
        channel_data: [channelData],
        device_id: Number(registerDeviceState.deviceId),
        gps_location: {
          address: registerDeviceState.addressSelectedInfo.address || "",
          city: registerData.city || "",
          state: registerData.state || "",
          number: registerData.complement || "",
          country: "",
          country_code: "",
          neighborhood: registerData.state || "",
          zipcode: registerData.cep.replace("-", "") || "",
          display_address:
            registerDeviceState.addressSelectedInfo.address || "",
          lat: registerDeviceState.addressSelectedInfo.lat,
          long: registerDeviceState.addressSelectedInfo.lng,
          acc: 0,
        },
        is_draft: false,
      };

      await registerDevice(body);

      addToast({
        title: t("deviceList.registerModal.onSuccess.title"),
        message: t("deviceList.registerModal.onSuccess.description"),
        type: "success",
      });

      removeDeviceFromListPendingRegistration(registerDeviceState.deviceId);
      refetchDeviceListActivated();

      return { ok: true };
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message || error.message;
        addToast({
          title: t("deviceList.registerModal.onError.title"),
          message: errorMessage,
          type: "error",
        });
      }

      return { ok: false };
    }
  }

  const { mutateAsync: onRegisterDeviceFn, isPending } = useMutation({
    mutationFn: onRegisterDevice,
  });

  const { data: filteredAddresses, isLoading } = useQuery({
    queryKey: ["filtered-addresses", registerDeviceState.addressSearch],
    queryFn: async () => {
      if (!registerDeviceState.addressSearch || !googleMapsIsLoaded) return [];

      const predictions = await getSuggestions(
        registerDeviceState.addressSearch
      );

      return predictions;
    },
  });

  async function onPlaceSelect(placeId?: string) {
    if (!placeId) return;

    const placeDetails = await getPlaceDetails(placeId);

    if (!placeDetails) return;

    const addressOptions = {
      address: placeDetails.formatted_address || "",

      cep:
        placeDetails.address_components?.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "",

      city:
        placeDetails.address_components?.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name || "",

      state:
        placeDetails.address_components?.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name || "",

      country:
        placeDetails.address_components?.find((component) =>
          component.types.includes("country")
        )?.long_name || "",

      lat: placeDetails.geometry?.location?.lat() || 0,
      lng: placeDetails.geometry?.location?.lng() || 0,
    };

    registerDeviceActions.setAddressSelectedInfo(addressOptions);
  }

  function formatAddress(
    filteredAddressesToFormat: google.maps.places.AutocompletePrediction[]
  ) {
    const options: GLSelectSearchOptions[] = filteredAddressesToFormat.map(
      (address) => {
        return {
          text:
            address.description.length > 60
              ? address.description.slice(0, 60) + "..."
              : address.description,
          id: String(address.place_id),
        };
      }
    );

    registerDeviceActions.setFilteredAddressesFormatted(options);
  }

  function refetchDeviceListActivated() {
    queryClient.invalidateQueries({
      queryKey: [
        "devices-activateds-list",
        paginationState.currentPage,
        paginationState.search,
      ],
    });
  }

  function removeDeviceFromListPendingRegistration(deviceId: number) {
    queryClient.setQueryData<GetDevicesPendingActivationResponse[] | undefined>(
      [
        "devices-pending-activation-list",
        paginationState.currentPage,
        paginationState.search,
      ],
      (data) => {
        if (!data) return;

        return data.filter((device) => device.device_id !== deviceId);
      }
    );
  }

  useEffect(() => {
    if (filteredAddresses) {
      formatAddress(filteredAddresses);
    }
  }, [filteredAddresses]);

  useEffect(() => {
    registerDeviceActions.setAddressIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    registerDeviceActions.setIsLoading(isPending);
  }, [isPending]);

  return {
    registerDeviceState,
    onPlaceSelect,
    onRegisterDeviceFn,
  };
};
